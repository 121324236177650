.wrapper {
  display: block;
  margin: 30px;
}

.userList {
  position: absolute;
  background: white;
  z-index: 10;
  border-radius: 4px;
  border:1px solid #c5c5c5;
  top: 56px;
}

.search {
  width: 500px;
  position: relative;
}

.userItem {
  width: 285px;
  padding: 7px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
.userItem:hover {
  background: #c0c0c0;
}
